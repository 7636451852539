// gatsby-browser.js

import "./src/styles/global.css"

export const onInitialClientRender = () => {
  // Delay the loading of Tawk.to script
  setTimeout(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date()
    ;(function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0]
      s1.async = true
      s1.src = "https://embed.tawk.to/66d5bb3850c10f7a00a3121b/1i6pe6luh"
      s1.charset = "UTF-8"
      s1.setAttribute("crossorigin", "*")
      s0.parentNode.insertBefore(s1, s0)
    })()
  }, 3000) // Load script 3 seconds after initial render
}
